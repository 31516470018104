import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { H3Sizes, LargeTypeSizes } from '@styles/Typography'
import { ThemeContext } from '@layout/Layout'
import ComponentContainer from '@layout/ComponentContainer'
import ComponentContent from '@blocks/ComponentContent'
import Link from '@elements/Link'

export const ListBlock = styled.div`
  &:not(:first-child) {
    margin-top: ${spacing(-2)};
  }

  .list-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid
      ${styledMap('theme', {
        dark: tokens.color('white', '02'),
        default: tokens.color('black', '02'),
      })};
    padding-top: ${spacing()};
    padding-bottom: ${spacing()};

    &:first-child {
      border-top: 1px solid
        ${styledMap('theme', {
          dark: tokens.color('white', '02'),
          default: tokens.color('black', '02'),
        })};
    }

    ${media.greaterThan('s')`
      flex-flow: row nowrap;
    `}
  }

  .list-row-inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 auto;

    &:only-child {
      ${media.greaterThan('s')`
        flex-flow: row nowrap;

        .list-item:last-child {
          flex: 0 0 auto;
          margin-right: 0;
          margin-left: auto;
        }
      `}
    }
  }

  .list-item {
    flex: 1 1 100%;

    ${media.greaterThan('m')`
      flex: 1 1 50%;
    `}
  }

  .label {
    ${LargeTypeSizes}
  }

  .button {
    flex: 0 0 auto;
    margin-right: 0;
    margin-left: ${spacing(2)};

    ${media.greaterThan('s')`
      ${H3Sizes}
      flex-flow: row nowrap;
      height: ${spacing(6)};
      margin-top: 0;
      padding-right: ${spacing(2)};
      padding-left: ${spacing(2)};
      margin-left: auto;
    `}
  }
`

export default ({
  list,
  textContent,
  className,
  containerWidth,
  contentAlign = 'left',
  isFlex,
}) => {
  const { theme } = useContext(ThemeContext)
  return (
    <ComponentContainer size={containerWidth} isFlex={isFlex}>
      {textContent && (
        <ComponentContent
          textContent={textContent}
          contentAlign={contentAlign}
        />
      )}
      <ListBlock className={className} theme={theme}>
        {list.map(({ label, content, link }, index) => {
          return (
            <div className="list-row" key={index}>
              <div className="list-row-inner">
                {label && (
                  <span
                    className="list-item label"
                    dangerouslySetInnerHTML={{ __html: label }}
                  />
                )}
                {content && (
                  <span
                    className="list-item"
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                )}
              </div>
              {link && <Link className="button" link={link} />}
            </div>
          )
        })}
      </ListBlock>
    </ComponentContainer>
  )
}

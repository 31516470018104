import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { FontStyleH1, H1Sizes } from '@styles/Typography'
import ComponentContainer from '@layout/ComponentContainer'
import ComponentContent from '@blocks/ComponentContent'
import Caption from '@elements/Caption'
import Image from '@elements/Image'
import { MagicItem } from '@hooks/useMagic'
import TextBanner from '@components/TextBanner'

export const Columns = styled.div`
  ${box({
    spacing: 'bottom',
    multiplier: 0.5,
    type: 'margin',
  })};
  ${H1Sizes}
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;

  .column {
    flex: 0 1 50%;

    &:first-child {
      margin-right: ${spacing(4)};
    }

    .image {
      height: 18rem;

      ${media.greaterThan('s')`
        height: 37.5rem;
      `}

      .gatsby-image-wrapper {
        height: 100%;
      }
    }

    .caption-inner {
      padding: 0;
    }
  }
`

export default ({
  className = '',
  bannerText,
  bannerTextRows = 3,
  images,
  designations,
  isFlex,
}) => {
  const bannerTextContent = [
    bannerText,
    bannerText,
    bannerText,
    bannerText,
  ].join('&nbsp;')

  return (
    <>
      <TextBanner
        duration="80"
        bannerText={bannerTextContent}
        isFlex={isFlex}
      />
      <ComponentContainer isFlex={isFlex} collapse>
        {images && (
          <Columns className="columns">
            {images.map(({ image, caption }, index) => {
              return (
                <div className="column" key={index}>
                  <MagicItem type="fadeUp">
                    <Image source={image} objectFit="contain" />
                  </MagicItem>
                  {caption && <Caption content={caption} />}
                </div>
              )
            })}
          </Columns>
        )}
      </ComponentContainer>
    </>
  )
}

export const query = graphql`
  fragment TextBannerFragment on WordPressAcf_text_banner {
    bannerText
    images {
      caption
      image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

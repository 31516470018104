import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { ContainerMaxWidth } from '@styles/Global'
import BlockQuote from '@elements/BlockQuote'
import { ComponentBase } from '@mixins/MixinsComponent'

export const Quote = styled.blockquote`
  ${ComponentBase}
`

export default ({ author, authorMeta, content, isFlex }) => {
  return (
    <Quote isFlex={isFlex} size="wide">
      <div className="inner">
        <BlockQuote author={author} authorMeta={authorMeta} content={content} />
      </div>
    </Quote>
  )
}

Quote.propTypes = {
  content: PropTypes.string,
  author: PropTypes.string,
  authorMeta: PropTypes.string,
}

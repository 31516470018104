import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import ComponentContainer from '@layout/ComponentContainer'

export const IframeContainer = styled.div`
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-bottom: 56.25%;
    width: 100%;
  }

  iframe {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`

export default ({ className, embed, containerWidth, isFlex }) => {
  return (
    <ComponentContainer size={containerWidth} isFlex={isFlex}>
      <IframeContainer className="iframe-container">
        <iframe
          title={embed}
          src={embed}
          frameBorder="0"
          allowFullScreen="allowfullscreen"
        />
      </IframeContainer>
    </ComponentContainer>
  )
}

import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { ThemeContext } from '@layout/Layout'
import MediaItem from '@blocks/MediaItem'
import Caption from '@elements/Caption'

export const MediaGridItem = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-start;
  grid-column: 6 / -1;

  > * {
    width: 100%;
  }

  &:first-child {
    grid-row: 1 / 4;

    &:only-child {
      grid-row: 1 / -1;

      ${media.lessThan('m')`
         grid-column: 1 / -1;
      `}
    }
  }

  &:not(:last-child) {
    .caption {
      margin-bottom: ${spacing(3)};

      ${media.greaterThan('s')`
        position: absolute;
        top: 100%;
        left: 0;
      `}
    }
  }

  ${styledMap('isFirstChildFullWidth', {
    true: css`
      grid-row: 3 / -1;

      ${media.greaterThan('s')`
        grid-row: 2 / -1;
      `}

      &:first-child {
        grid-column: 1 / -1;
        z-index: ${tokens.z('base')};
      }
    `,
    default: css`
      grid-row: 3 / -1;

      &:first-child {
        grid-column: 1 / 11;
        z-index: ${tokens.z('aboveContent')};
      }
    `,
  })};
`

export default ({
  media,
  isFirstChildFullWidth,
  caption,
  captionSize,
  className,
}) => {
  const { theme } = useContext(ThemeContext)
  return (
    <MediaGridItem
      className={className}
      isFirstChildFullWidth={isFirstChildFullWidth}
      mediaType={media.mediaType}
      theme={theme}
    >
      <MediaItem media={media} />
      {caption && <Caption content={caption} size={captionSize} />}
    </MediaGridItem>
  )
}

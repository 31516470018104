import React from 'react'
import { graphql } from 'gatsby'
import ComponentContainer from '@layout/ComponentContainer'
import CallToActionRow from '@components/CallToAction/CallToActionRow'
import TeaserListRow from '@components/TeasersList/TeaserListRow'
import ComponentContent from '@blocks/ComponentContent'

export default ({
  postType,
  posts,
  pages,
  textContent,
  isFlex,
  hasFeatured,
  containerWidth,
  contentAlign = 'center',
  textAlign = 'left',
}) => {
  if (postType === 'pages') {
    return (
      <ComponentContainer size={containerWidth} isFlex={isFlex}>
        <ComponentContent
          textContent={textContent}
          contentAlign={contentAlign}
          textAlign={textAlign}
        />
        <CallToActionRow
          callToActions={pages}
          contentAlign={contentAlign}
          textAlign={textAlign}
          hasFeatured={hasFeatured}
        />
      </ComponentContainer>
    )
  }

  return (
    <ComponentContainer size={containerWidth} isFlex={isFlex}>
      <ComponentContent
        textContent={textContent}
        contentAlign={contentAlign}
        textAlign={textAlign}
      />
      <TeaserListRow teasers={posts} />
    </ComponentContainer>
  )
}

import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { H3, BaseType } from '@styles/Typography'
import { PageContentRTE } from '@mixins/MixinsPage'
import { ThemeContext } from '@layout/Layout'
import TextContent from '@elements/TextContent'
import Image from '@elements/Image'

export const Column = styled.div`
  ${BaseType}
  ${PageContentRTE}
  width: 100%;

  .column-image {
    margin-bottom: ${spacing(4)};
    position: relative;
    z-index: ${tokens.z('base')};

    ${styledMap('isLogo', {
      true: css`
      background: ${tokens.color('white')};;

      &::before {
        display: block;
        content: '';
        width: 100%;
        padding-bottom: 65%;
      }

      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: ${tokens.z('content')};
        padding: ${spacing(4)} ${spacing(5)};

        .gatsby-image-wrapper {
          width: 100%;
          height: 100%;
        }
      `,
      default: '',
    })};
    }
  }

  .heading {
    a::before {
      bottom: 0.1em;
    }
  }
`

export default ({ heading, content, image, url, className, isLogo }) => {
  const { theme } = useContext(ThemeContext)
  return (
    <Column className={className} theme={theme} isLogo={isLogo}>
      {image && (
        <div className="column-image">
          <Image source={image} objectFit="contain" />
        </div>
      )}
      <div className="content">
        {url ? (
          <H3 className="heading" textCase="none">
            <a
              href={url}
              rel="noopener noreferrer"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
          </H3>
        ) : (
          <H3
            className="heading"
            textCase="none"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}

        {content && <TextContent content={content} />}
      </div>
    </Column>
  )
}

import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, box, media } from '@tokens'
import ComponentContainer from '@layout/ComponentContainer'
import { ComponentBase } from '@mixins/MixinsComponent'

const GalleryContainer = styled.div`
  ${ComponentBase}
  width: 100%;
  max-width: none;

  .hero + & {
    margin-top: 0;
  }

  ${styledMap('size', {
    full: css`
      padding-right: ${spacing(2)};
      padding-left: ${spacing(2)};
      margin-bottom: ${spacing(2)};

      ${media.greaterThan('s')`
        padding-right: ${spacing(2)};
        padding-left: ${spacing(2)};
        margin-bottom: ${spacing(2)};
      `}
    `,
    default: '',
  })};
`

export default ({ className = '', size, children, isFlex }) => {
  return (
    <GalleryContainer className={className} size={size} isFlex={isFlex}>
      <div className="inner">{children}</div>
    </GalleryContainer>
  )
}

import React from 'react'
import Columns from '@blocks/Columns'
import Column from '@blocks/Column'
import ComponentContent from '@blocks/ComponentContent'
import ComponentContainer from '@layout/ComponentContainer'

export default ({ columns, textContent, containerWidth = 'wide', isFlex }) => {
  return (
    <ComponentContainer isFlex={isFlex} size={containerWidth}>
      <ComponentContent
        textContent={textContent}
        headingSize="large"
        contentAlign="left"
      />
      <Columns className="columns" short>
        {columns &&
          columns.map(({ ...column }, index) => {
            return <Column key={index} {...column} />
          })}
      </Columns>
    </ComponentContainer>
  )
}

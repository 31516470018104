import React from 'react'
import { graphql } from 'gatsby'
import ComponentContent from '@blocks/ComponentContent'
import styled, { css } from 'styled-components'
import { tokens, media, spacing, box } from '@tokens'
import styledMap from 'styled-map'
import { ComponentBase } from '@mixins/MixinsComponent'
import TextContent from '@elements/TextContent'
import Links from '@blocks/Links'
import Heading from '@elements/Heading'

export const TextWithInlineChildren = styled.section`
  ${ComponentBase}
  width: 100%;

  .content-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    max-width: none;

    ${styledMap('noGrid', {
      true: css`
        ${media.greaterThan('l')`
            flex-flow: row nowrap;
        `}
      `,
      default: css`
        ${media.greaterThan('m')`
            flex-flow: row nowrap;
        `}
      `,
    })};
  }

  .inline-content {
    flex: 1 1 100%;
  }

  .inline-element {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    ${media.greaterThan('s')`
      justify-content: center;
    `}

    ${styledMap('noGrid', {
      true: css`
        flex: 1 1 auto;
        margin-bottom: ${spacing(4)};
        justify-content: center;
        order: -1;

        ${media.greaterThan('l')`
          position: absolute;
          top: 0;
          left: 100%;
          margin-bottom: 0;
          margin-left: ${spacing(6)};
        `};
      `,
      default: css`
        flex: 0 1
          ${styledMap('hasPost', {
            true: tokens.get('magicNumbers.teaserMobile'),
            default: 'auto',
          })};
        margin-top: ${spacing(4)};

        ${media.greaterThan('m')`
          margin-top: 0;
          margin-left: ${spacing(6)};
        `}
      `,
    })};
  }
`

export default ({
  textContent: { selectedFields, heading, content, link },
  designations,
  isFlex,
  className = '',
  headingSize,
  contentAlign = 'left',
  children,
  textSize,
  hasPost,
  size = 'wide',
  collapse,
  noGrid,
}) => {
  const hasChildren = !!children
  return (
    <TextWithInlineChildren
      className={className}
      designations={designations}
      isFlex={isFlex}
      hasChildren={hasChildren}
      contentAlign={contentAlign}
      headingSize={headingSize}
      hasPost={hasPost}
      size={size}
      collapse={collapse}
      noGrid={noGrid}
    >
      <div className="inner">
        <ComponentContent size={children ? 'xwide' : 'narrow'}>
          <Heading
            heading={heading}
            selectedFields={selectedFields}
            headingSize={headingSize}
          />
          <div className="content-container">
            <div className="inline-content">
              <TextContent
                content={content}
                selectedFields={selectedFields}
                contentAlign={contentAlign}
                textSize={textSize}
              />
              <Links link={link} selectedFields={selectedFields} />
            </div>
            {hasChildren && <div className="inline-element">{children}</div>}
          </div>
        </ComponentContent>
      </div>
    </TextWithInlineChildren>
  )
}

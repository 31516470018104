import React, { useEffect, useRef, useState, useContext } from 'react'
import axios from 'axios'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { ButtonStyles } from '@mixins/MixinsButton'
import { spacing, media } from '@tokens'
import { useForm, FormContext } from 'react-hook-form'
import { Element, scroller } from 'react-scroll'
import { ThemeContext } from '@layout/Layout'
import { useGform } from '@hooks/useGform'
import { useSiteSource } from '@hooks/useSiteSource'
import ComponentContainer from '@layout/ComponentContainer'
import ComponentContent from '@blocks/ComponentContent'
import Arrow from '@svgs/Arrow'
import Loader from '@elements/Loader'
import Field from './Fields'

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${spacing(6)};
  grid-row-gap: ${spacing(8)};

  ${media.greaterThan('740px')`
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: ${spacing(12)};
  `}

  .form-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;

    ${media.greaterThan('740px')`
      grid-column-start: 2;
    `}
  }
`

export const Button = styled.button`
  ${ButtonStyles}
`

const getFieldName = (id) => {
  return `input_${String(id).replace('.', '_')}`
}

export default ({ formId, className, containerWidth = 'wide', isFlex }) => {
  const form = useGform(formId)
  if (!form) {
    return <div>Incorrect form ID</div>
  }
  const { formFields: fields, buttonText } = form
  const { theme } = useContext(ThemeContext)
  const [isLoading, setIsLoading] = useState(false)
  const [hasSubmit, setHasSubmit] = useState(false)
  const [formHeight, setFormHeight] = useState('auto')

  const ref = useRef()
  // TODO make these dynamic values
  const siteSourceUrl = useSiteSource(false)
  const apiUrl = `${siteSourceUrl}/wp-json/gf/v2/forms/${formId}`
  const lambdaUrl =
    'https://us-central1-gearbox-253501.cloudfunctions.net/hoyne-submit-gf'

  const methods = useForm({
    validateCriteriaMode: 'all',
  })
  const { handleSubmit } = methods

  const sendGform = async (values) => {
    const result = await axios.post(lambdaUrl, {
      responseType: 'json',
      withCredentials: true,
      crossdomain: true,
      data: {
        baseUrl: apiUrl,
        payload: values,
      },
    })
  }

  const onSubmit = (data) => {
    sendGform(data)
    setFormHeight(ref.current.getBoundingClientRect().height)
    setIsLoading(false)
    scroller.scrollTo('form-waypoint', {
      duration: 500,
      delay: 0,
      offset: -80,
      smooth: 'easeInOutQuart',
    })
    setTimeout(() => {
      setHasSubmit(true)
    }, 550)
  }

  useEffect(() => {
    if (hasSubmit) {
      setFormHeight('auto')
    }
  }, [hasSubmit])

  return (
    <ComponentContainer size={containerWidth} isFlex={isFlex}>
      <Element name="form-waypoint" />
      <motion.div
        ref={ref}
        animate={{
          height: formHeight,
          transition: {
            duration: 0.4,
          },
        }}
      >
        {!hasSubmit ? (
          <FormContext {...methods}>
            <Form
              className={className}
              onSubmit={handleSubmit(onSubmit)}
              theme={theme}
            >
              {fields &&
                fields.map((field, index) => {
                  const name = getFieldName(field.databaseId)
                  return (
                    <Field
                      key={index}
                      name={name}
                      {...field}
                      isRequired={field.isRequired === 'true'}
                    />
                  )
                })}
              <div className="form-footer">
                <Loader isLoading={isLoading} />
                <Button className="submit" type="submit" large>
                  {buttonText}
                  <Arrow className="arrow" />
                </Button>
              </div>
            </Form>
          </FormContext>
        ) : (
          <ComponentContent
            textContent={{
              selectedFields: ['heading', 'content'],
              heading: 'Thanks for you for your submission!',
              content: 'We will get in touch with you shortly.',
            }}
          />
        )}
      </motion.div>
    </ComponentContainer>
  )
}

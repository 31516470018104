import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Image from '@elements/Image'
import Caption from '@elements/Caption'
import GalleryContainer from './GalleryContainer'
import GalleryImages from './GalleryImages'
import { MagicItem } from '@hooks/useMagic'

const GalleryComponent = ({
  gallery,
  caption,
  className = '',
  featuredStyle = 'landscape',
  imageAlign = 'left',
  isFlex,
  containerWidth,
}) => {
  if (!gallery) return null

  const captionAlign =
    (gallery.length === 2 && imageAlign === 'left') ||
    (gallery.length === 3 && imageAlign === 'right')
      ? 'right'
      : 'left'

  return (
    <GalleryContainer
      className={className}
      size={containerWidth}
      isFlex={isFlex}
    >
      <GalleryImages
        imageCount={gallery.length}
        featuredStyle={featuredStyle}
        imageAlign={imageAlign}
      >
        {gallery &&
          gallery.map((image, index) => {
            return (
              <MagicItem key={index} className="gallery-image">
                <Image source={image} />
              </MagicItem>
            )
          })}
      </GalleryImages>
      {caption && (
        <Caption
          content={caption}
          align={captionAlign}
          featuredStyle={featuredStyle}
          imageCount={gallery.length}
        />
      )}
    </GalleryContainer>
  )
}

export default GalleryComponent

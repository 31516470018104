import React from 'react'
import { graphql } from 'gatsby'
import Teaser from '@components/Teasers'
import CallToAction from '@components/CallToAction/CallToAction'
import TextWithInlineChildren from './TextWithInlineChildren'

export default ({
  textContent,
  postType,
  designations,
  isFlex,
  posts,
  pages,
  headingSize,
  contentAlign,
  textAlign,
  className = '',
}) => {
  return (
    <TextWithInlineChildren
      designations={designations}
      isFlex={isFlex}
      contentAlign={contentAlign}
      headingSize={headingSize}
      textContent={textContent}
      className={className}
      textAlign={textAlign}
      hasPost
    >
      {postType === 'post' &&
        posts &&
        posts.map(({ id, uri, topCategory, title, eventDate, image, type }) => {
          const { color, name: category } = topCategory
          return (
            <Teaser
              key={id}
              uri={uri}
              color={color}
              category={category}
              title={title}
              date={eventDate}
              image={image}
              type={type}
              className="teaser"
            />
          )
        })}

      {postType === 'page' &&
        pages &&
        pages.map(
          (
            { heading: ctaHeading, image, link, backgroundColor, imageType },
            index
          ) => {
            return (
              <CallToAction
                key={`${link.title}-${index}`}
                className="call-to-action"
                heading={ctaHeading}
                image={image}
                link={link}
                imageType={imageType}
                backgroundColor={backgroundColor}
              />
            )
          }
        )}
    </TextWithInlineChildren>
  )
}

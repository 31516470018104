import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import ComponentContainer from '@layout/ComponentContainer'
import Heading from '@elements/Heading'
import HeadingWithMedia from '@blocks/HeadingWithMedia'
import MagicItem from '@hooks/useMagic'

export default ({
  heading,
  headingSize = 'large',
  containerWidth = 'wide',
  isFlex,
  media,
  hasMedia,
}) => {
  return (
    <ComponentContainer size={containerWidth} isFlex={isFlex}>
      {hasMedia && media ? (
        <HeadingWithMedia
          heading={heading}
          headingSize={headingSize}
          media={media}
        />
      ) : (
        <Heading headingSize={headingSize}>
          <MagicItem type="textFade">{heading}</MagicItem>
        </Heading>
      )}
    </ComponentContainer>
  )
}

import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import Heading from '@elements/Heading'
import Teaser from '@components/Teasers/'

export const TeaserListRow = styled.div`
  ${media.greaterThan('s')`
    justify-content: center;
    flex-flow: row nowrap;
  `}

  > .heading {
    margin-bottom: ${spacing(6)};
    text-align: left;
    margin-left: auto;
    margin-right: auto;

    ${media.greaterThan('s')`
      text-align: center;
    `}
  }

  .inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: stretch;
    max-width: ${tokens.get('maxWidth.narrow')};
    margin-left: auto;
    margin-right: auto;

    ${media.greaterThan('s')`
      justify-content: center;
    `}
  }

  .teaser {
    margin-bottom: ${spacing(2)};

    &:last-child {
      margin-bottom: 0;
    }

    ${media.greaterThan('s')`
      flex-basis: calc(50% - ${spacing(3)});
      margin-bottom: 0;

      & + .teaser {
        margin-left: ${spacing(6)};
      }
    `}
  }
`

export default ({ heading, headingSize, teasers }) => {
  return (
    <TeaserListRow>
      {heading && (
        <Heading headingSize={headingSize} className="heading">
          {heading}
        </Heading>
      )}
      <div className="inner">
        {teasers &&
          teasers.map(
            ({ id, topCategory, title, eventDate, image, type, uri }) => {
              const { color, name: category } = topCategory
              return (
                <Teaser
                  key={id}
                  uri={uri}
                  color={color}
                  category={category}
                  title={title}
                  date={eventDate}
                  image={image}
                  type={type}
                  className="teaser"
                />
              )
            }
          )}
      </div>
    </TeaserListRow>
  )
}

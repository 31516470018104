import { useStaticQuery, graphql } from 'gatsby'
// No variables permitted in static Queries ... YET!
export const useGform = (formId) => {
  const {
    wp: {
      gravityForms: { forms },
    },
  } = useStaticQuery(
    graphql`
      query GForms {
        wp {
          gravityForms {
            forms {
              buttonText
              title
              databaseId
              formFields {
                type
                placeholder
                label
                isRequired
                errorMessage
                defaultValue
                description
                databaseId
                cssClass
                choices {
                  isSelected
                  text
                  value
                }
              }
            }
          }
        }
      }
    `
  )

  return forms.find((form) => parseInt(form.databaseId, 10) === formId) || {}
}

import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { tokens, media, spacing, box } from '@tokens'
import FormBlock from '@components/Form'
import Slider from '@components/Slider'
import HeadingLarge from '@components/HeadingLarge'
import ListBlock from '@components/ListBlock'
import Text from '@components/Text'
import TextBanner from '@components/TextBanner/TextBannerComponent'
import TextWithRelatedPost from '@components/Text/TextWithRelatedPost'
import Quote from '@components/Quote'
import MediaGrid from '@components/MediaGrid'
import Iframe from '@components/Iframe'
import Gallery from '@components/Gallery'
import RelatedPosts from '@components/RelatedPosts'
import Columns from '@components/Columns'

// Map components
const components = {
  columnsBlock: Columns,
  formBlock: FormBlock,
  iframe: Iframe,
  gallery: Gallery,
  heading: HeadingLarge,
  listBlock: ListBlock,
  relatedPosts: RelatedPosts,
  slider: Slider,
  text: Text,
  textBanner: TextBanner,
  textWithRelatedPost: TextWithRelatedPost,
  mediaGrid: MediaGrid,
  quote: Quote,
}

export const ComponentSpacer = styled.div`
  ${box({
    spacing: 'top',
    multiplier: 1,
    type: 'margin',
  })};
  width: 100%;
`
/**
 * Take component data and output using the correct component template
 */
export const FlexComponents = (
  { layout, componentProps, isFlex = true },
  index
) => {
  if (layout === 'spacer') {
    return <ComponentSpacer />
  }

  const Component = components[layout]

  if (!Component) {
    return <div key={index}>No Component Template for {layout}</div>
  }

  return <Component {...componentProps} isFlex={isFlex} />
}

FlexComponents.propTypes = {
  layout: PropTypes.string,
  componentProps: PropTypes.object,
}

export default FlexComponents

// export const flexibleContentGraphQl = graphql`
//   fragment flexibleContentFragment on wordpress__flex {
//     layout
//     gallery {
//       ...GalleryComponentFragment
//     }
//     formBlock {
//       ...FormComponentFragment
//     }
//     listBlock {
//       ...ListBlockComponentFragment
//     }
//     mediaGrid {
//       ...MediaGridComponentFragment
//     }
//     slider {
//       ...SliderComponentFragment
//     }
//     heading {
//       ...HeadingLargeComponentFragment
//     }
//     text {
//       ...TextComponentFragment
//     }
//     textWithRelatedPost {
//       ...TextWithRelatedPostComponentFragment
//     }
//     textBanner {
//       ...TextBannerFragment
//     }
//     quote {
//       ...QuoteComponentFragment
//     }
//     relatedPosts {
//       ...RelatedPostsFragment
//     }
//   }
// `

import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { ThemeContext } from '@layout/Layout'
import { HugeTypeStyles, MetaStyles } from '@styles/Typography'
import { tokens, media, spacing, box } from '@tokens'

export const BlockQuote = styled.blockquote`
  .content {
    ${HugeTypeStyles}
    margin-bottom: ${spacing(4)};s
  }

  .author,
  .author-meta {
    display: block;
    letter-spacing: -${tokens.get('type.letterSpacing.micro')};
  }

  .author-meta {
    color: ${styledMap('theme', {
      dark: tokens.color('text', 'darkFainter'),
      default: tokens.color('text', 'lightFainter'),
    })};
  }

  .quote-image {
    display: none;

    ${media.greaterThan('s')`
      display: block;
      align-self: flex-end;
      margin-bottom: ${spacing(-4)};
    `}
  }
`

export default ({ author, authorMeta, content }) => {
  const { theme } = useContext(ThemeContext)

  return (
    <BlockQuote theme={theme}>
      <div className="inner">
        <p className="content" dangerouslySetInnerHTML={{ __html: content }} />

        {(author || authorMeta) && (
          <span className="author">
            {author}
            {authorMeta && <span className="author-meta">{authorMeta}</span>}
          </span>
        )}
      </div>
    </BlockQuote>
  )
}

import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import ComponentContainer from '@layout/ComponentContainer'
import MediaGridItem from './MediaGridItem'

export const MediaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto auto ${spacing(3)} ${spacing(3)} auto;

  ${media.greaterThan('s')`
    grid-template-rows: auto 12.5rem ${spacing(4)} ${spacing(4)} auto;
  `}

  ${media.greaterThan('l')`
    grid-template-rows: auto 12.5rem ${spacing(8)} ${spacing(8)} auto;
  `}
`

export default ({
  mediaItems,
  className,
  containerWidth,
  isFlex,
  gridType: initialGridType,
}) => {
  const gridType = initialGridType || mediaItems[0].media.mediaType
  const isFirstChildFullWidth =
    gridType === 'illustration' ||
    (gridType === 'image' && mediaItems.length === 1)
  return (
    <ComponentContainer size={containerWidth} isFlex={isFlex}>
      <MediaGrid
        className={className}
        isFirstChildFullWidth={isFirstChildFullWidth}
      >
        {mediaItems.map(({ media, caption }, index) => {
          const captionSize = isFirstChildFullWidth ? '5/12' : '1/2'
          return (
            <MediaGridItem
              key={index}
              media={media}
              isFirstChildFullWidth={isFirstChildFullWidth}
              caption={caption}
              captionSize={index === 0 ? captionSize : ''}
            />
          )
        })}
      </MediaGrid>
    </ComponentContainer>
  )
}
